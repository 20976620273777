import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthLayout } from 'components';
import { useSelector } from 'hooks';
import {
  LogInPage,
  SignUpPage,
  ConfirmEmailPage,
  ForgotPasswordStepOne,
  ForgotPasswordStepTwo,
  CompanyDetailsFormPage,
} from 'pages';
import { PaymentPlatformsFormPage } from 'pages/Authentication/PaymentPlatformsFormPage/PaymentPlatformsFormPage';
import { appDataLinksSelector } from 'store/selectors/appDataSelector';
import { showCodatIntegration } from 'portal.config';

const Authentication = () => {
  const appLinks = useSelector(appDataLinksSelector);

  const defaultRoutes = (
    <>
      <Route index element={<LogInPage />} />
      <Route path='login/google' element={<LogInPage allowGoogleLogin />} />
      <Route path='signup' element={<SignUpPage />} />
      <Route path='signup/:partnerSlug' element={<SignUpPage />} />
      <Route path='signup/google' element={<SignUpPage propsAllowGoogleLogin />} />
      <Route path='confirm-email' element={<ConfirmEmailPage />} />
      <Route path='forgot-password' element={<ForgotPasswordStepOne />} />
      <Route path='new-password' element={<ForgotPasswordStepTwo />} />
    </>
  );

  const companyDetailsRoutes = (
    <>
      <Route path='company-details' element={<CompanyDetailsFormPage />} />
      <Route path='company-details/:partnerSlug' element={<CompanyDetailsFormPage />} />
      <Route index element={<CompanyDetailsFormPage />} />
    </>
  );

  const paymentPlatformRoutes = (
    <>
      <Route path='payment-platforms' element={<PaymentPlatformsFormPage />} />
      <Route index element={<PaymentPlatformsFormPage />} />
    </>
  );

  const renderAuthRoutes = () => {
    if (appLinks?.createAccount) return companyDetailsRoutes;
    else if (appLinks?.createFinancialPlatforms && showCodatIntegration)
      return paymentPlatformRoutes;
    else return defaultRoutes;
  };

  return (
    <Routes>
      <Route path={'/'} element={<AuthLayout />}>
        {renderAuthRoutes()}
        <Route path='*' element={<Navigate to='/' />} />
      </Route>
    </Routes>
  );
};

export default Authentication;
