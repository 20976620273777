// import { envToBool } from './envToBool';

export const getIsLeanTechEnabled = () => {
  return false;

  // Temoorary disable leantech

  // const leanTechValue = localStorage.getItem('leantech');

  // if (leanTechValue) {
  //   return envToBool(leanTechValue);
  // }
};
