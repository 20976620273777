/* eslint-disable camelcase */
import { useEffect, useMemo, useState } from 'react';
import { Typography, HorizontalProgress, Button, Loader, FinancialPlatform } from 'components';
import { useTranslate } from 'react-polyglot';
import { authSteps } from '../Authentication.config';
import { useDispatch, useSelector } from 'hooks';
import {
  getCompanyFinancialPlatforms,
  requestFinancialPlatformConnection,
} from 'store/thunks/financialPlatformSliceThunk';
import {
  areFinancialPlatformsLoadingSelector,
  selectedFinancialPaymentPlatformsSelector,
  selectedFinancialAccountingPlatformsSelector,
} from 'store/selectors/financialPlatformsSelector';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { getSelfData } from 'store/thunks/appDataSliceThunk';
import {
  dropFinancialPlatformModalState,
  openFinancialPlatformModal,
} from 'store/slices/financialPlatformsSlice';
import { FinancialPlatformConnectionType } from 'types';
import { appDataSelfSelector } from 'store/selectors/appDataSelector';
import { getIsLeanTechEnabled } from 'utils/getIsLeanTechEnabled';

const checkbox = <CheckIcon sx={{ fill: 'white', width: '2rem' }} />;

export const PaymentPlatformsFormPage = () => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const paymentPlatforms = useSelector(selectedFinancialPaymentPlatformsSelector);
  const accountingPlatforms = useSelector(selectedFinancialAccountingPlatformsSelector);
  const areFinancialPlatformsLoading = useSelector(areFinancialPlatformsLoadingSelector);
  const [isNoPlatformOptionSelected, setIsNoPlatformOptionSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [leanTechEnabled, setLeanTechEnabled] = useState(false);
  const self = useSelector(appDataSelfSelector);

  useEffect(() => {
    dispatch(getCompanyFinancialPlatforms());
    setLeanTechEnabled(getIsLeanTechEnabled());

    return () => {
      dispatch(dropFinancialPlatformModalState());
    };
  }, []);

  const showLeanTechIntegration = useMemo(() => {
    return Boolean(self._links?.connectLeanTech) && leanTechEnabled;
  }, [self._links?.connectLeanTech, leanTechEnabled]);

  const isAnyPlatformSelected = useMemo(() => {
    const areAnyFinancialPlatformsSelected =
      paymentPlatforms.available.length > 0 || paymentPlatforms.onDemand.length > 0;
    const areAnyAccountingPlatformsSelected =
      accountingPlatforms.available.length > 0 || accountingPlatforms.onDemand.length > 0;

    return areAnyFinancialPlatformsSelected || areAnyAccountingPlatformsSelected;
  }, [
    paymentPlatforms.available.length,
    paymentPlatforms.onDemand.length,
    accountingPlatforms.available.length,
    accountingPlatforms.onDemand.length,
  ]);

  useEffect(() => {
    if (isAnyPlatformSelected) {
      setIsNoPlatformOptionSelected(false);
    }
  }, [isAnyPlatformSelected]);

  const isContinueAllowed = useMemo(() => {
    return isAnyPlatformSelected || isNoPlatformOptionSelected;
  }, [isNoPlatformOptionSelected, isAnyPlatformSelected]);

  const handleOpenFinancialPlatformsModal = () => {
    dispatch(openFinancialPlatformModal(showLeanTechIntegration ? 'banks' : undefined));
  };

  const handleContinue = async () => {
    try {
      setIsLoading(true);

      if (isNoPlatformOptionSelected) {
        await dispatch(
          requestFinancialPlatformConnection({
            platformName: null,
            connectionType: FinancialPlatformConnectionType.ACCOUNTING,
          }),
        );
      }

      await dispatch(getSelfData());

      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Typography variant='h1'>{t('signup.paymentPlatforms')}</Typography>
      <HorizontalProgress options={authSteps} highlightOptions={3} />
      <Typography variant='body1' color='white'>
        For faster approval, please connect your platforms. Don&apos;t have any platforms? No
        worries, we&apos;ll still get your cash flowing quickly.
      </Typography>
      {areFinancialPlatformsLoading ? (
        <Box minHeight='5rem' display='flex' justifyContent='center' alignItems='center'>
          <Loader />
        </Box>
      ) : (
        <>
          {paymentPlatforms.available.map((platform) => (
            <FinancialPlatform key={platform.platformName} {...platform} isCompact={true} />
          ))}
          {paymentPlatforms.onDemand.map((platform) => (
            <FinancialPlatform key={platform.platformName} {...platform} isCompact={true} />
          ))}
          {accountingPlatforms.available.map((platform) => (
            <FinancialPlatform key={platform.platformName} {...platform} isCompact={true} />
          ))}
          {accountingPlatforms.onDemand.map((platform) => (
            <FinancialPlatform key={platform.platformName} {...platform} isCompact={true} />
          ))}
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            border={'2px solid'}
            borderColor={'darkBlue.light'}
            padding='.5rem'
            paddingLeft='1rem'
            borderRadius='.5rem'
            width='100%'
            minHeight='5rem'
            sx={{ cursor: 'pointer', '&:hover': { borderColor: 'common.white' } }}
            onClick={handleOpenFinancialPlatformsModal}
          >
            <Typography variant='h4'>Connect platforms</Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            border={'2px solid'}
            borderColor={isNoPlatformOptionSelected ? 'common.white' : 'darkBlue.light'}
            padding='.5rem'
            paddingLeft='1rem'
            borderRadius='.5rem'
            width='100%'
            minHeight='5rem'
            sx={{ cursor: 'pointer' }}
            onClick={
              isAnyPlatformSelected
                ? undefined
                : () => setIsNoPlatformOptionSelected(!isNoPlatformOptionSelected)
            }
          >
            <Typography variant='h4'>No payment platform</Typography>
            <Box display='flex' alignItems='center' gap='2rem' mr='1rem'>
              {isNoPlatformOptionSelected ? checkbox : null}
            </Box>
          </Box>
          <Button
            variant='contained'
            disabled={!isContinueAllowed}
            onClick={handleContinue}
            isLoading={isLoading}
          >
            {t('global.continue')}
          </Button>
        </>
      )}
    </div>
  );
};
