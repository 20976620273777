import { Typography, CompanyDetailsForm, HorizontalProgress } from 'components';
import { useTranslate } from 'react-polyglot';
import { authSteps } from '../Authentication.config';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const CompanyDetailsFormPage = () => {
  const t = useTranslate();
  const { partnerSlug } = useParams();

  useEffect(() => {
    if (partnerSlug) {
      sessionStorage.setItem('partner', partnerSlug);
    }
  }, []);

  return (
    <div>
      <Typography variant='h1'>{t('signup.signup')}</Typography>
      <HorizontalProgress options={authSteps} highlightOptions={2} />
      <CompanyDetailsForm />
    </div>
  );
};

export default CompanyDetailsFormPage;
