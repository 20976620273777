import React, { useEffect, useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { countryCurrencies, incorporationCountryOptions } from './CompanyDetailsForm.config';
import { TextField, Select, Button } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { postCompanyDetails } from 'store/thunks/companySliceThunk';
import { useTranslate } from 'react-polyglot';
import { getSelfData } from 'store/thunks/appDataSliceThunk';
import { appDataIndustryOptionsSelector } from 'store/selectors/appDataSelector';
import { currencySignOrIsoCode } from 'utils/currencySignOrIsoCode';
import { useParams } from 'react-router';

const initialFormData = {
  companyName: '',
  lastYearRevenue: '',
  phoneNumber: '',
  incorporationCountry: '',
  industry: '',
  partner: '',
};

type InputNames = keyof typeof initialFormData;

export type CompanyDetailsForm = Omit<typeof initialFormData, 'lastYearRevenue' | 'partner'> & {
  lastYearRevenue: string | number;
};

const CompanyDetailsForm = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const industryOpts = useSelector(appDataIndustryOptionsSelector);
  const [formData, setFormData] = useState(initialFormData);
  const [fieldHelpers, setFieldHelpers] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const { partnerSlug } = useParams();

  useEffect(() => {
    const partnerSlug = sessionStorage.getItem('partner');

    if (partnerSlug) {
      setFormData((formData) => ({ ...formData, partner: partnerSlug }));
    }
  }, []);

  useEffect(() => {
    if (partnerSlug) {
      setFormData((formData) => ({ ...formData, partner: partnerSlug }));
    }
  }, [partnerSlug]);

  const currencyStringBasedOnCountry = useMemo(() => {
    return currencySignOrIsoCode(
      countryCurrencies[formData.incorporationCountry as keyof typeof countryCurrencies] ?? 'USD',
    );
  }, [formData.incorporationCountry]);

  const setFieldHelperContent = (name: InputNames, content: string) => {
    setFieldHelpers((fieldHelpers) => ({ ...fieldHelpers, [name]: content }));
  };

  const handleValidateInput = (name: InputNames, value: string) => () => {
    switch (name) {
      case 'industry':
        if (value.length === 0) {
          setFieldHelperContent(name, 'Industry is mandatory!');
        }

        break;
      case 'companyName':
        if (value.length === 0) {
          setFieldHelperContent(name, 'Company name is mandatory!');
        }
        break;
      case 'lastYearRevenue':
        if (value.length === 0) {
          setFieldHelperContent(name, 'Last year revenue is mandatory!');
        }
        break;
      case 'incorporationCountry':
        if (value.length === 0) {
          setFieldHelperContent(name, 'Your country of incorporation must be selected!');
        }
        break;
      case 'phoneNumber':
        if (value.length === 0) {
          setFieldHelperContent(name, 'You must provide a phone number!');
        }
        break;

      default:
        break;
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.name as InputNames;

    if (fieldHelpers[inputName]) {
      setFieldHelperContent(inputName, '');
    }
    setFormData((formData) => ({ ...formData, [inputName]: e.target.value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string | number>) => {
    const inputName = e.target.name as InputNames;

    if (fieldHelpers[inputName]) {
      setFieldHelperContent(inputName, '');
    }

    setFormData((formData) => ({ ...formData, [inputName]: e.target.value }));
  };

  const isFormValid = () => {
    const { companyName, incorporationCountry, lastYearRevenue, phoneNumber, industry } = formData;

    const isCompanyNameValid = companyName.length > 0;
    const isCountryValid = incorporationCountry.length > 0;
    const isMrrValid = lastYearRevenue.length > 0;
    const isPhoneNumberValid = phoneNumber.length > 0;
    const isIndustryValid = industry.length > 0;

    return (
      isCompanyNameValid && isCountryValid && isMrrValid && isPhoneNumberValid && isIndustryValid
    );
  };

  const handleFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    evt.preventDefault();

    await dispatch(postCompanyDetails(formData));
    await dispatch(getSelfData());
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <TextField
        label={t('companyDetails.legalName')}
        name='companyName'
        id='company'
        size='small'
        value={formData.companyName}
        onChange={handleInputChange}
        fullWidth
        error={Boolean(fieldHelpers.companyName)}
        helperText={fieldHelpers.companyName}
        onBlur={handleValidateInput('companyName', formData.companyName)}
      />
      <Select
        id='incorporationCountry'
        name='incorporationCountry'
        label={t('companyDetails.incorpCountry')}
        size='small'
        fullWidth
        options={incorporationCountryOptions}
        value={formData.incorporationCountry}
        onChange={handleSelectChange}
        error={Boolean(fieldHelpers.incorporationCountry)}
        helperText={fieldHelpers.incorporationCountry}
        onBlur={handleValidateInput('incorporationCountry', formData.incorporationCountry)}
      />

      <Select
        label={t('companyDetails.industry')}
        size='small'
        id='industry'
        name='industry'
        fullWidth
        options={industryOpts}
        value={formData.industry}
        onChange={handleSelectChange}
        error={Boolean(fieldHelpers.industry)}
        helperText={fieldHelpers.industry}
        onBlur={handleValidateInput('industry', formData.industry)}
      />

      <TextField
        id='lastYearRevenue'
        name='lastYearRevenue'
        type='number'
        label={`${t('companyDetails.lyr')} (${currencyStringBasedOnCountry})`}
        size={'small'}
        value={formData.lastYearRevenue}
        onChange={handleInputChange}
        fullWidth
        error={Boolean(fieldHelpers.lastYearRevenue)}
        helperText={fieldHelpers.lastYearRevenue}
        onBlur={handleValidateInput('lastYearRevenue', formData.lastYearRevenue)}
      />
      <TextField
        label={t('companyDetails.phone')}
        name='phoneNumber'
        id='phoneNumber'
        size='small'
        type='number'
        fullWidth
        value={formData.phoneNumber}
        onChange={handleInputChange}
        error={Boolean(fieldHelpers.phoneNumber)}
        helperText={fieldHelpers.phoneNumber}
        onBlur={handleValidateInput('phoneNumber', formData.phoneNumber)}
      />

      <Button
        type='submit'
        variant='contained'
        size='medium'
        disabled={!isFormValid()}
        sx={{ mt: '2rem' }}
        isLoading={isLoading}
      >
        {t('global.submit')}
      </Button>
    </form>
  );
};

export default CompanyDetailsForm;
